.react-jinke-music-player-main {
  .music-player-panel {
      z-index: 999999 !important;

      .panel-content {
          .img-content {
              background-size: cover !important;
              background-position: center;
          }
      }

      .loop-btn,
      .audio-lists-btn {
          display: none !important;
      }

      .progress-bar {
          background-color: unset !important;

          .rc-slider-track,
          .rc-slider-handle {
              background-color: #9797DE !important;
          }
      }

      .player-content {
          svg {
              color: #6e6b7b !important;
          }
      }
  }
}

.react-jinke-music-player-main .music-player-panel .panel-content .rc-slider-handle,
.react-jinke-music-player-main .music-player-panel .panel-content .rc-slider-track {
  background-color: #9797DE !important;
}


